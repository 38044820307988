<template>
    <div>
        <b-table
            ref="refMembershipsListTable"
            :items="benefits"
            :fields="tableColumns"
            :tbody-tr-class="rowClass"
            filter
            primary-key="id"
            :sort-by.sync="isSortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDir"
            class="position-relative mb-0"
            v-if="benefits.length"
            responsive
            small
            style="overflow:auto; font-size: smaller;"
            :busy.sync="isLoadingBenefits"
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner> <br>
                    <strong>Cargando Beneficios</strong>
                </div>
            </template>
            <template #cell(code)="data">
                <span class="text-nowrap">
                    {{ data.value ? data.value : 'N/A' }}
                </span>
            </template>


            <template #cell(showReport)="data">
                <span class="text-nowrap">
                    {{ data.value ? 'Sí': 'No' }}
                </span>
            </template>

            <template #cell(excludeFeeCalculation)="data">
                <span class="text-nowrap">
                    {{ data.value ? 'Sí': 'No' }}
                </span>
            </template>

            <template #cell(status)="row">
                <span class="text-nowrap">
                    <b-badge pill :variant="row.item.status ? 'success': 'danger'" >
                    {{ row.item.status ? "Activo" : "Desactivado" }}
                    </b-badge>
                </span>
            </template>

            <template #cell(includebalance)="data">
                <span class="text-nowrap">
                    {{ data.value ? 'Sí': 'No' }}
                </span>
            </template>

            <template #cell(includebooking)="data">
                <span class="text-nowrap">
                    {{ data.value ? 'Sí': 'No' }}
                </span>
            </template>

            <template #cell(temporalDataStatus)="data">
                <span class="text-nowrap">
                    {{ data.value ? 'Esperando por Autorización': '-' }}
                </span>
            </template>

            <!-- actions de edicion está ligado a Status -->
            <template #cell(actions)="row">
                <b-spinner label="Loading..." variant="success" v-if="!!benefitToEdit && benefitToEdit == row.item.id"/>
                <div v-else>
                    <b-button
                        size="sm"
                        :to="{name: 'edit-benefits', params: {idBenefit: row.item.id}}"
                        variant="primary"
                        :class="{ 'd-none': (!row.item.status || (!!benefitToEdit && benefitToEdit == row.item.id)) && !row.item.temporalDataStatus }"
                    > <feather-icon icon="Edit2Icon" size="12" />
                    </b-button>

                    <b-form-checkbox
                        :class="{ 'd-none': row.item.status || (!row.item.status && row.item.temporalDataStatus) }"
                        v-model="row.item.status"
                        v-b-tooltip.hover.v-warning
                        title="Cambiar Status"
                        :value="true"
                        switch
                        inline
                        :unchecked-value="false"
                        @change="changeStatus(row.item, $event)"
                        class="custom-control-success"
                    />
                </div>
                </template>
        </b-table>

        <b-alert
            variant="danger"
            v-else-if="!benefits.length"
            show
        >
        <div class="alert-body text-center">
            <span><strong>¡Sin registros!</strong> No se encontraron beneficios registrados.</span>
        </div>
        </b-alert>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { showAlertMessage } from '@/helpers/helpers'


export default {
    mixins: [utils],
    props:{
        benefits:{
            type: Array,
            required: true,
        },
        tableColumns:{
            type: Array,
            required: true,
        },
        sortBy:{
            type: String,
            required: true,
        },
        isSortDirDesc:{
            type: Boolean,
            required: true,
        },
        refMembershipsListTable:{
            type: Object,
            required: false,
        },
        isLoadingBenefits:{
            type: Boolean,
            required: true,
        },
    },
    data(){
        return {
            isSortDir: this.isSortDirDesc,
            isSortBy: this.sortBy,
            benefitToEdit: null
        }
    },
    computed:{
        ...mapState('auth', ['user']),
        ...mapState('fivesClubCatalogs', ['filter',]),
        porHousing(){
            if(this.filter.query !== '') return this.filter.queryBenefits
            else this.filter.queryBenefits = ''
        }
    },

    methods:{
        ...mapMutations('fivesClubCatalogs', ['setSelectedBenefit','setBenefitsProducts']),
        ...mapActions('fivesClubCatalogs', ['updateBenefit', 'fetchBenefits']),
        typeBenefit(id) {
            const typeBenefit = this.typesBenefits.find((benefit) => benefit.id === id )
            return typeBenefit ? typeBenefit.name : ""
        },
        groupTypeBenefit(id) {
            const groupTypeBenefit = this.groupsTypesBenefits.find( (benefit) => benefit.id === id )
            return groupTypeBenefit ? groupTypeBenefit.name : ""
        },

        async changeStatus(item, event){
            this.benefitToEdit = item.id
            const payload = {
                code: item.code,
                fp: item.fp,
                id: item.id,
                idGroupBenefit: item.groupsTypesBenefits,
                idTypeBenefit: item.typesBenefits,
                idUser: this.user.idUser,
                includeBalance: Boolean(item.includebalance),
                includeBooking: Boolean(item.includebooking),
                name: item.name,
                nameEng: item.nameEng,
                origin: item.origin,
                productId: item.product ? item.product.id : '',
                status: Boolean(event),
                visibleOnWeb: item.visibleOnWeb,
            }

            const { status, message } = await this.updateBenefit(payload)
            this.benefitToEdit = null
            if (status) {
                showAlertMessage('Ok: Status actualizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
                this.$emit('reload-benefits')
            } else {
                item.status = !item.status
                showAlertMessage('No se pudo actualizar el status', 'InfoIcon', 'Hubo un error al actualizar el status. Por favor intentelo más tarde', 'warning', 4000, 'bottom-right' )
            }
        },
    }
}
</script>