<template>
  <b-card>
    <Filters
      :perPage="perPage"
      :perPageOptions="perPageOptions"
      :searchQuery="searchQuery"
      :isLoadingSomething="isLoadingBenefits"
      @refresh-data="loadBenefits"
      @change-per-page="changePerPage"
      @search-in-table-records="searchInTableRecords"
    />
    <TableBenefits
      :benefits="benefitsData"
      :tableColumns="tableColumns"
      :sortBy="sortBy"
      :isSortDirDesc="isSortDirDesc"
      :refMembershipsListTable="refMembershipsListTable"
      :isLoadingBenefits="isLoadingBenefits"
      @set-tab-index="setTabIndex"
      @reload-benefits="loadBenefits"
    />
    <PaginationTable
      :currentPage="currentPage"
      :totalRows="filteredProfits.length"
      :perPage="perPage"
      @change-page="changePage"
    />
  </b-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { utils } from '@/modules/fivesClub/mixins/utils'
import Filters from '@/modules/fivesClub/components/catalogs/benefits/Filters'
import TableBenefits from '@/modules/fivesClub/components/catalogs/benefits/TableBenefits'
import PaginationTable from '@/modules/fivesClub/components/catalogs/benefits/PaginationTable'

export default {
  mixins: [utils],
  components:{
    TableBenefits,
    PaginationTable,
    Filters
  },
  async created(){
    await this.loadBenefits()
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 15, 20, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      refMembershipsListTable : null,
      isLoadingBenefits: false,
      tableColumns: [
        { key: 'code', label: 'Código' },
        { key: 'name', label: 'Nombre' },
        { key: 'nameEng', label: 'Nombre en ingles' },
        { key: 'groupsbenefitsname', label: 'Grupo de beneficio' ,class: 'text-center', },
        { key: 'typesbenefitsname', label: 'Tipo de beneficio' },
        { key: 'originname', label: 'Origen',class: 'text-center'  },
        { key: 'includebalance', label: 'Incluido en Balance' , class: 'text-center', },
        { key: 'includebooking', label: 'Incluido en Booking' , class: 'text-center', },
        { key: 'status', label: 'Status', class: 'text-center',  },
        { key: 'temporalDataStatus', label: 'Status revision', class: 'text-center',  },
        { key: 'actions', label: 'Acciones' },
      ],
    }
  },
  computed: {
    ...mapGetters('fivesClubCatalogs',['filteredProfits']),
    ...mapState('auth', ['user']),
    benefitsData(){
      return this.filteredProfits.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
    },
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['fetchBenefits']),
    async loadBenefits(){
      this.isLoadingBenefits = true
      await this.fetchBenefits()
      this.isLoadingBenefits = false
    },
    changePage(page){
      this.currentPage = page
    },
    changePerPage(perPage){
      this.perPage = perPage
    },
    searchInTableRecords(string){
      this.searchQuery = string 
    },
    setTabIndex(index){
      this.$emit('set-tab-index', index)
    },
    reloadBenefits(){

    }
  },
};
</script>