<template>
  <ListBenefits @set-tab-index="setTabIndex"/>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import ListBenefits from '@/modules/fivesClub/components/catalogs/benefits/ListBenefits'

export default {
  components:{
    ListBenefits,
  },
  created() {

  },
  data() {
    return {
      tabIndex: 0
    }
  },
 computed: {
    ...mapState('fivesClubCatalogs',['selectedBenefit']),
  },
  methods: {
    ...mapMutations('fivesClubCatalogs',['setSelectedBenefit']),
    setTabIndex( index ){
      this.tabIndex = index
    },
    clickTab(){
      this.setSelectedBenefit(null)
    }
  },
};
</script>
